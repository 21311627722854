const renderKlarnaSnippet = (htmlSnippet) => {
  const checkoutContainer = document.getElementById(
    "klarna-checkout-container"
  );
  checkoutContainer.innerHTML = htmlSnippet;
  var scriptsTags = checkoutContainer.getElementsByTagName("script");
  // This is necessary otherwise the scripts tags are not going to be evaluated
  for (var i = 0; i < scriptsTags.length; i++) {
    var parentNode = scriptsTags[i].parentNode;
    var newScriptTag = document.createElement("script");
    newScriptTag.type = "text/javascript";
    newScriptTag.text = scriptsTags[i].text;
    parentNode.removeChild(scriptsTags[i]);
    parentNode.appendChild(newScriptTag);
  }
};

const getCreateOrderData = ({
  order_amount,
  order_lines,
  merchant_urls,
  merchant_data,
}) => ({
  currency: "sek",
  locale: "sv",
  purchase_country: "SE",
  purchase_currency: "sek",
  order_amount,
  order_tax_amount: 0,
  order_lines,
  merchant_urls,
  options: { allowed_customer_types: ["person", "organization"] },
  customer: { type: "organization" },
  merchant_data,
});

module.exports = { renderKlarnaSnippet, getCreateOrderData };
