import React, { useEffect, useState, useCallback } from "react";
import styled from "styled-components";
import queryString from "query-string";
import SEO from "../components/SEO";
import axios from "axios";
import useFirebase from "../../firebase/useFirebase";
import { renderKlarnaSnippet } from "../utils/klarna";
import { generatePassword } from "../utils/password";
import { addYears, format } from "date-fns";
import {
  PageSectionArticle,
  PageSection,
  PageSectionBackdrop,
  PageSectionWrapper,
  PageSectionHeader,
  PageSectionChildElementGrid,
  PageUnorderedList,
  PageListItem,
  TextInput,
  CustomButtonSmall,
} from "../components/common";
import { PageHeaderImage } from "../components/common/PageHeaderImage";
import { respondTo } from "../styles";

const CREATING_USER_STATUSES = {
  NOT_CREATED: "NOT_CREATED",
  CREATING_USER: "CREATING_USER",
  USER_CREATED: "USER_CREATED",
  FAILED_CREATING_USER: "FAILED_CREATING_USER",
};

const ConfirmationArticle = styled(PageSectionArticle)`
  ${PageSectionChildElementGrid}

  ${respondTo.lg`
      padding: 40px 0 0;
    `}
`;

const ConfirmationArticleContent = styled.div`
  grid-column: 2 / 4;
  max-width: 550px;
  margin-bottom: 50px;

  ${respondTo.xl`
      max-width: 740px;
    `}

  h2 {
    margin-bottom: 16px;
  }

  ${PageUnorderedList} {
    margin-bottom: 20px;
  }
`;

const ConfirmationNewEmailForm = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  ${TextInput} {
    margin-bottom: 22px;

    ${respondTo.lg`
      margin-bottom: 0;
      margin-right: 22px;
  `}
  }

  ${respondTo.lg`
    flex-direction: row;
    align-items: center;
  `}
`;

const Confirmation = () => {
  const firebase = useFirebase();
  const [creatingUserStatus, setCreatingUserStatus] = useState(
    CREATING_USER_STATUSES.NOT_CREATED
  );
  const [error, setError] = useState("");
  const [newEmail, setNewEmail] = useState("");
  const [userInfo, setUserInfo] = useState({
    order_id: "",
    email: "",
    given_name: "",
    family_name: "",
  });

  const [order_id, setOrderId] = useState(null);

  useEffect(() => {
    const { order_id: orderId } = queryString.parse(
      window.location.search.slice(1)
    );
    if (orderId) {
      setOrderId(orderId);
    }
  }, []);

  const getOrder = useCallback(async () => {
    if (order_id) {
      const {
        data: {
          billing_address: { email, given_name, family_name },
          html_snippet,
          merchant_data,
          started_at,
        },
      } = await axios.get("/.netlify/functions/getOrder", {
        params: { order_id },
      });
      renderKlarnaSnippet(html_snippet);
      setUserInfo({
        order_id,
        email,
        given_name,
        family_name,
        subscriptionType: JSON.parse(merchant_data)?.subscriptionType,
        endOfSubscriptionDate: format(
          addYears(new Date(started_at), 1),
          "yyyy-MM-dd"
        ),
      });
    }
  }, [order_id]);

  useEffect(() => {
    getOrder();
  }, [getOrder]);

  const sendResetPasswordEmail = useCallback(
    () =>
      firebase.auth().sendPasswordResetEmail(userInfo.email, {
        url: `${window.location.origin}/login`,
        handleCodeInApp: true,
      }),
    [firebase, userInfo.email]
  );

  const createUser = useCallback(async () => {
    const {
      email,
      family_name,
      given_name,
      subscriptionType,
      endOfSubscriptionDate,
    } = userInfo;
    setCreatingUserStatus(CREATING_USER_STATUSES.CREATING_USER);
    const tempPassword = generatePassword();

    try {
      const userCredential = await firebase
        .auth()
        .createUserWithEmailAndPassword(email, tempPassword);
      const user = userCredential.user;
      await user.updateProfile({
        displayName: `${given_name} ${family_name}`,
      });
      await sendResetPasswordEmail();

      await axios.post(`/.netlify/functions/addSubscriber`, {
        email: email,
        firstName: given_name,
        lastName: family_name,
        userUID: user.uid,
        subscriptionType,
        endOfSubscriptionDate,
      });

      setCreatingUserStatus(CREATING_USER_STATUSES.USER_CREATED);
    } catch (error) {
      // TODO: handle error
      setCreatingUserStatus(CREATING_USER_STATUSES.FAILED_CREATING_USER);
      setError(error.code);
      return null;
    }
  }, [firebase, sendResetPasswordEmail, userInfo]);

  useEffect(() => {
    if (
      firebase &&
      userInfo.email &&
      creatingUserStatus === CREATING_USER_STATUSES.NOT_CREATED
    ) {
      createUser();
    }
  }, [firebase, userInfo, creatingUserStatus, createUser]);

  const handleNewEmail = ({ target }) => {
    setNewEmail(target.value);
  };

  const submitNewUser = (e) => {
    e.preventDefault();
    setCreatingUserStatus(CREATING_USER_STATUSES.NOT_CREATED);
    const updatedUserInfo = { ...userInfo, email: newEmail };
    setUserInfo(updatedUserInfo);
  };

  return (
    <>
      <SEO title="Orderbekräftelse" />
      <PageSectionWrapper style={{ minHeight: "1000px" }}>
        <PageSection bgColor="var(--pmbCararra)">
          <PageSectionBackdrop bgColor="var(--creamWhite)">
            <PageSectionHeader style={{ minHeight: "716px" }}>
              <PageHeaderImage />
              <div id="klarna-checkout-container" />
            </PageSectionHeader>
          </PageSectionBackdrop>
        </PageSection>
        <PageSection bgColor="var(--pmbCararra)">
          <PageSectionBackdrop bgColor="var(--pmbCararra)">
            <ConfirmationArticle>
              <ConfirmationArticleContent>
                {creatingUserStatus === CREATING_USER_STATUSES.USER_CREATED && (
                  <>
                    <h2>Välkommen!</h2>
                    <PageUnorderedList>
                      <PageListItem>
                        <p>
                          Ett mail har skickats till{" "}
                          <span>{userInfo.email}</span> med en länk där du får
                          välja lösenord.
                        </p>
                      </PageListItem>
                      <PageListItem>
                        <p>Logga sedan in och börja!</p>
                      </PageListItem>
                    </PageUnorderedList>
                  </>
                )}
                {creatingUserStatus ===
                  CREATING_USER_STATUSES.FAILED_CREATING_USER &&
                  (error === "auth/email-already-in-use" ? (
                    <>
                      <h2>Hoppsan!</h2>
                      <PageUnorderedList>
                        <PageListItem>
                          <p>
                            Användare <span>{userInfo.email}</span> är upptagen.
                          </p>
                        </PageListItem>
                        <PageListItem>
                          <p>Välj ny email för att skapa en användare.</p>
                        </PageListItem>
                      </PageUnorderedList>

                      <ConfirmationNewEmailForm onSubmit={submitNewUser}>
                        <label className="sr-only" htmlFor="newEmail">
                          Ny e-postadress
                        </label>
                        <TextInput
                          type="email"
                          name="newEmail"
                          id="newEmail"
                          onChange={handleNewEmail}
                          placeholder="Fyll i ny e-postadress"
                          customWidth="400px"
                          customWidthMobile="320px"
                        ></TextInput>

                        <CustomButtonSmall btnColor="white" type="submit">
                          Skicka
                        </CustomButtonSmall>
                      </ConfirmationNewEmailForm>
                    </>
                  ) : (
                    <>
                      <h2>Hmmm ...</h2>
                      <PageUnorderedList>
                        <PageListItem>
                          <p>Tyvärr kunde vi inte skapa användaren nu.</p>
                        </PageListItem>
                        <PageListItem>
                          <p>
                            Din användare kommer att skapas inom 4 timmar och
                            därefter skickas ett mail till{" "}
                            <span>{userInfo.email}</span>.
                          </p>
                        </PageListItem>
                      </PageUnorderedList>
                    </>
                  ))}
              </ConfirmationArticleContent>
            </ConfirmationArticle>
          </PageSectionBackdrop>
        </PageSection>
      </PageSectionWrapper>
    </>
  );
};

export default Confirmation;
